document.addEventListener("DOMContentLoaded", function() {
  var cbInstance = Chargebee.getInstance();

  // By default, chargebee.js script will look for all the buttons 
  // present on page load and binds click events for opening checkout. 
  // However, if you are using Angular, React or Vue Frameworks, 
  // you must manually register click events to the buttons by calling Chargebee.registerAgain() 
  // after the HTML component is mounted/created.
  // https://www.chargebee.com/checkout-portal-docs/drop-in-tutorial.html.

  Chargebee.registerAgain()

  let cart = cbInstance.getCart();
  
  // Timeout is setup because Safari loads the script before the DOM is loaded.
  setTimeout(() => {
    if (document.getElementById("essential-licenses-monthly-chf")) {
      // Click events on "Get started" buttons.
      document
        .getElementById("essential-licenses-monthly-chf")
        .addEventListener("click", () => {
          let product = cbInstance.initializeProduct(
            "essential-licenses-monthly-chf"
          );
          cart.replaceProduct(product);
          cart.proceedToCheckout();
        });

      document
        .getElementById("business-licenses-monthly-chf")
        .addEventListener("click", () => {
          let product = cbInstance.initializeProduct(
            "business-licenses-monthly-chf"
          );
          cart.replaceProduct(product);
          cart.proceedToCheckout();
        });

      document
        .getElementById("premium-licences-monthly-chf")
        .addEventListener("click", () => {
          let product = cbInstance.initializeProduct(
            "premium-licences-monthly-chf"
          );
          cart.replaceProduct(product);
          cart.proceedToCheckout();
        });
    }
  }, 100);
  
  cbInstance.setCheckoutCallbacks(function(cart) {
    // You can get the plan name for which the checkout happened like below
    return {
        close: function() {
          sessionStorage.setItem('spinner', 'stop');
        },
        success: function(hostedPageId) {
          sessionStorage.setItem('paymentId', hostedPageId);
          sessionStorage.setItem('spinner', 'stop');

          // Hosted page id will be unique token for the checkout that happened
          // You can pass this hosted page id to your backend
          // and then call our retrieve hosted page api to get subscription details
          // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
        }
    }
  });
});
